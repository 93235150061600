<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        <!--begin::User-->
        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
                    <div class="row">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="name">{{$t('installment.installment_id')}}</label>
                            <input v-model="filters.installment_id" type="text" id="name" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_installment_due_date">{{$t('installment.from_installment_due_date')}}</label>
                            <input v-model="filters.from_installment_due_date" type="date" id="from_installment_due_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_installment_due_date">{{$t('installment.to_installment_due_date')}}</label>
                            <input v-model="filters.to_installment_due_date" type="date" id="to_installment_due_date" class="form-control">
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('installment.period_installment')}}</label>
                            <select name="" id="period_installment" v-model="filters.period_installment" class="custom-select" :class="validation && validation.period_installment ? 'is-invalid' : ''">
                                <option v-for="row in period_installment_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                            </select>

                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('installment.client')}}</label>
                            <!-- <select name="" id="client_id" v-model="filters.client_id" class="custom-select">
                                <option v-for="row in customers" :value="row.id" :key="row.id">{{ row.name }}</option>
                            </select> -->
                            <multiselect v-model="client"
                                :placeholder="$t('installment.client')"
                                label="name"
                                track-by="id"
                                :options="customers"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getCustomers($event)">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
<!--                        <export-data :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.installment_agreements')"></export-data>-->
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">


                    <template slot="id_serial_no" slot-scope="props">
                        <p>{{props.row.id }}</p>
                    </template>
                    <template slot="client_info" slot-scope="props">
                        <p>{{$t('installment.client_no')}}: {{ props.row.client_id}}</p>
                        <p>{{$t('installment.client_name')}}: {{ props.row.client_name}}</p>

                    </template>
                    <template slot="payment_details" slot-scope="props">
                        <p>{{ props.row.installment_amount}} {{ props.row.currency_name}}</p>
                        <p>{{$t('installment.invoice')}}: #{{ props.row.invoice_id}}</p>

                    </template>
                    <template slot="installment_amount" slot-scope="props">
                        <p>{{ props.row.installment_account}}</p>
                        <p>{{ props.row.period_installment_name}}</p>
                    </template>
                    <template slot="due_date" slot-scope="props">
                        <p>{{ props.row.installment_due_date}}</p>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('installments.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('installments.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>

                        <v-btn icon color="pink" :to="`/installment/installment/view/${props.row.id}`">
                            <v-icon small v-b-tooltip.hover :title="$t('view')" class="mr-2">mdi-eye</v-icon>
                        </v-btn>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-installment",
        components: {},
        data() {
            return {
                mainRoute: 'installmentmanagement/installments',
                subMainRoute: 'installmentmanagement/installment',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    installment_id: null,
                    client_id: null,
                    from_installment_due_date: null,
                    to_installment_due_date: null,
                    status: null,
                    period_installment: null,
                },

                customers: [],
                client: null,
                period_installment_list: [],
                status_list: [],

                columns: ['id_serial_no', 'client_info', 'payment_details', 'installment_amount', 'due_date', 'actions'],
            }
        },
        watch: {
            client: function (val) {
                if (val) {
                    this.filters.client_id = val.id;
                } else {
                    this.filters.client_id = null;
                }
            },
        },
        computed: {
            // json_fields: function () {
            //     let fields = {};
            //     fields[this.$t('installment.id_serial_no')] = 'id_serial_no';
            //     fields[this.$t('installment.client_info')] = 'client_info';
            //     fields[this.$t('installment.payment_details')] = 'payment_details';
            //     fields[this.$t('installment.installment_amount')] = 'installment_amount';
            //     fields[this.$t('installment.due_date')] = 'due_date';
            //
            //     return fields;
            // },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        id_serial_no: that.$t('installment.id_serial_no'),
                        client_info: that.$t('installment.client_info'),
                        payment_details: that.$t('installment.payment_details'),
                        installment_amount: that.$t('installment.installment_amount'),
                        due_date: that.$t('installment.due_date'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {

            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.installment_agreements")}]);
            this.getPeriodInstallment();
            this.getStatusList();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.installment_id = '';
                this.filters.from_installment_due_date = '';
                this.filters.to_installment_due_date = '';
                this.filters.status = '';
                this.filters.period_installment = '';
                this.filters.client_id = '';
                this.client = '';

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'installment.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getPeriodInstallment() {
                ApiService.get(this.mainRouteDependency + "/period_installment").then((response) => {
                    this.period_installment_list = response.data.data;
                });
            },
            getCustomers(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/customers`,{params:{filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
                }else{
                    this.customers = [];
                }
            },
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/installment_status").then((response) => {
                    this.status_list = response.data.data;
                });
            },


        },
    };
</script>
